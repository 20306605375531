@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle On Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

.upload-area {
  text-align: center;
  padding: 20px;
  border: 3px blue dashed;
  width: 100%;
  margin: auto;
}

.ewqTBN {
  max-width: 100% !important;
}

.mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  text-align: center;
  border: 1px solid #d3d3d3;
}

.dropzone-wrapper {
  border: 2px dashed #91b0b3;
  color: #92b0b3;
  position: relative;
  height: 200px;
}
.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 80%;
  top: 25px;
  font-size: 16px;
}

.dropzone, .dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover, .dropzone-wrapper.dragover {
  background: #ecf0f5;
}

.rating {
  width: 120px;
  height: 24px;
  position: relative;
  background-color: gray;
}

.rating progress.rating-bg {
  -webkit-appearance: none;
  -moz-appearence: none;
  appearance: none;
  border: none;
  display: inline-block;
  height: 24px;
  width: 100%;
  color: orange;
}

.rating progress.rating-bg::-webkit-progress-value {
  background-color: orange;
}

.rating progress.rating-bg::-moz-progress-bar {
  background-color: orange;
}

.rating svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preview {
  position: relative;
	display: block;
	width: 100%;
  border-radius: "20px";
  cursor: pointer;
	
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

/* .preview:hover {
  display: block;
  height: 100% !important;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */

input[type=radio] {
  accent-color: black !important;
}

.section__find {
  z-index: 9999;
  height: 75px;
  padding: 17px !important;
  position: absolute !important;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #0d0d0d;
  text-align: center;
}

.section__find span {
  font-size: 27px;
  font-weight: 600;
  line-height: 30px;
}

.accordion-item {
  border: 0px !important;
}

.accordion-button {
  background: #0d0d0d !important;
  padding: 10px 15px !important;
  color: white !important;
}

.accordion-body {
  background: #1a1a1a !important;
  color: white !important;
}